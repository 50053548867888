
:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  background-color: #EBEBEB;
  padding: 0;
  font-family: "Poppins", sans-serif;
  height: 100%;
  min-height: 100vh;
}


@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.loginwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.logincard {
  border-radius: 17px;
  background: var(--White, #FFF);
  box-shadow: 0px 0px 11.7px 1px rgba(48, 169, 226, 0.23);
  padding: 20px;
  display: flex;
  max-width: 700px;
  width: 700px;
  justify-content: space-around;
  gap: 26px;
  height: 310px;
  align-items: center;
  position: relative;
  z-index: 9999;
}

.leftlogin h5 {
  color: var(--Brand-Purple-Main, #29166F);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}
.logincard:after {
  content: '';
  position: absolute;
  background: #30A9E2;
  width: 0%;
  left: 18px;
  height: 7px;
  transition: all 0.5s;
  bottom: -8px;
  z-index: 1;
  border-radius: 0px 0 15px 15px;
}
.logincard.highlight:after {
  width: 95%;

}
.rightlogin h3 {
  color: var(--Grey-Dark, #585858);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

label.form-label {
  color: var(--Grey-Dark, #585858);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.leftlogin {
  flex: 0 0 40%;
  text-align: center;
}

.rightlogin {
  flex: 0 0 50%;
}
.logform { overflow: auto;}
.btnright {
  float: right;
}

.formbtn {
  border-radius: 9px;
  background: var(--Brand-Blue-Main, #30A9E2);
  border: 0;
  min-width: 100px;
  height: 41px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.formbtn:hover{ background: var(--Brand-Blue-Dark, #155674);}
.sitelinks ul {
  display: flex;
  list-style: none;
  gap: 30px;
  margin-top: 18px;
  padding: 0;
}

.sitelinks { max-width:700px; width:700px;display: flex;justify-content: flex-end;padding-right: 50px; }

.sitelinks ul a {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control {
  border-radius: 4px;
  border: 1px solid var(--Grey-Main, #AFAFAF);
  height: 41px;
}

.form-control:focus { box-shadow: none; }

.inputwrp {
  position: relative;
}

.inputwrp svg {
  position: absolute;
  top: 14px;
  font-size: 16px;
  left: 10px;
  color: #585858;
}

.inputwrp input {
  padding-left: 32px;
}
.otp-field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--Grey-Main, #AFAFAF);
}
p.resend.mt-3 {
  color: var(--Grey-Dark, #585858);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

span.timer {
  color: #00B957;
}

span.timer svg {
  color: #585858;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.btnicon svg {
  position: relative;
  right: 8px;
  color: #187142;
}

.dashlogo img {
  max-width: 90px;
}

.sidelogobox {
  padding: 9px 0;
  border-bottom: 1px solid var(--Brand-Purple-Light, #D1C5FF);
}
aside .ps-sidebar-container {
  border-right: 1px solid var(--Brand-Purple-Light, #D1C5FF);
  background-color: #fff;
}

aside .dashlogo {
  margin-left: 15px;
}

aside span.ps-menu-icon {
  border-radius: 24.038px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  color: #585858;
  height: 47px;
  position: relative;
  transition: all 0.5s;
  width: 47px;
}

aside span.ps-menu-icon svg {
  font-size: 20px;
}

.sidemenus li.ps-menuitem-root {
  padding: 22px 0;
}

.sidemenus span.ps-menu-icon:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 3px;
  background: #bfe7fa;
  bottom: -47px;
}

.sidemenus .ps-menuitem-root:last-child span.ps-menu-icon:after{
 display: none;
}


.tophdr {
  width: calc(100% - 280px);
  margin-left: 280px;
  background: #fff;
  height:69px;
  border-bottom: 1px solid var(--Brand-Purple-Light, #D1C5FF);

}

.maincontent{
  width: calc(100% - 280px);
  margin-left: 280px;
}

.ps-collapsed + .tophdr {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.ps-collapsed + .tophdr + .maincontent {
  margin-left: 80px;
  width: calc(100% - 80px );
}
.welcomebox {
  border-radius: 10px;
  background: var(--White, #FFF);
  width:80%;
  padding:60px 0;
  margin: 8% auto;
  text-align: center;
}

.welcometext h2 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 30px;
  font-style: normal;
  line-height: 150%; /* 45px */
}

.welcometext p {
  color: var(--Grey-Dark, #160E31);
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
}

.sitebtn {
  border-radius: 6px;
  background: var(--Green-Main, #00B957);
  border: 0;
  padding: 6px 15px;
  color: var(--White, #FFF);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: all 0.5s;
  display: inline-block;
  min-width: 210px;
  height: 44px;
}

.sitebtn:hover {
  background: #30a9e2;
  color:#fff;
}

.contentbox {
  border-radius: 10px;
  background: var(--White, #FFF);
  width: 80%;
  padding: 0 0 12px 0px;
  margin: 55px auto 0;
}

.contentbody {
  padding: 0 20px;
  height: 400px;
  overflow-y: auto;
}

.contenthdr {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #AFAFAF;
  margin-bottom: 12px;
  padding-bottom: 10px;
}
.sidemenus li.active .ps-menu-icon,.sidemenus li a:hover .ps-menu-icon {
  background: #29166F;
  color: #fff;
}

.sidemenus li.active .ps-menu-icon::after {
  background: #29166f;
}
.contentbody p {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; 
}

.contentbody h6 {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.contentbody h5 {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.contenthdr h4 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 150%; /* 33px */
}

.contenthdr .statictime {
  margin: 0;
  border-radius: 5px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  padding: 4px 10px;
  color: var(--Brand-Blue-Dark, #155674);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%; /* 21px */
}

.contentbtnwrap {
  text-align: center;
  margin-top: 22px;
}

.contentbtnwrap .sitebtn {
  min-width: 280px;
  padding: 10px 15px;
}

.topdashhdr {
  padding-right: 25px;
  gap: 15px;
}

.notf_icn {
  border-radius: 19px;
  background: var(--Grey-Light, #EBEBEB);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notf_icn svg {
  font-size: 16px;
}

.hdrprof_menu img {
  width: 42px;
}

.answers {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.answers label {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  /* 22.5px */
}

.questionwrap h6 {
  font-size: 14px;
}

.questionwrap {
  margin-bottom: 10px;
}

.qstnbtn {
  min-width: 180px !important;
}

.sitebtn.disabled {
  background: #AFAFAF;
  cursor: not-allowed;
}



.contentbody::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #BFE7FA;
}

.contentbody::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.contentbody::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #30A9E2;
}
.alertbox {
  border-radius: 9px;
  background: var(--Brand-Purple-Light, #D1C5FF);
  padding: 7px;
  width: 82%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -45px;
}

.alertbox p {
  margin:0;
  text-align:center;
  font-size:14px;
  font-weight:600;
}

.contentwrap {
  padding-bottom: 50px;
  position: relative;
}

.dflexbtwn { display:flex; justify-content:space-between; align-items:center; }
.scorebox {
  border-radius: 10px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding:20px 25px;
}
.scorenumb {
  width: 130px;
  height: 130px;
}

.scoretext {
  padding-left: 30px;
  width: 70%;
}

.scorewrap {
  margin: 55px auto 0;
  width: 85%;
}
.resumwrap svg {
  color: #00B957;
}
.assementscore .CircularProgressbar .CircularProgressbar-path {
  stroke: #00B957;
}
.assementscore .CircularProgressbar .CircularProgressbar-text {
  fill: #00B957;
  font-weight: 500;
}
.assementscore.failed .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff2200;
}

.assementscore.failed .CircularProgressbar .CircularProgressbar-text {
  fill: #ff2200;
  font-weight: 500;
}

.failed .scoretext span {
  color: #ff2200;
}
.scoretext h5 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 26px;
  font-style: normal;
  line-height: 150%; /* 45px */
}

.scoretext p {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
}

.scoretext span {
  color: var(--Green-Dark, #187142);
  font-size: 25.2px;
  font-style: normal;
  line-height: 150%; /* 40.8px */
}

button.retakebtn {
  border-radius: 6px;
  background: var(--Green-Light, #C1FFDE) !important;
  color: var(--Green-Dark, #187142) !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

p.scores {
  margin: 0;
}

.prflbtns{
  display: flex;
  align-items: center;
  gap: 25px;
}

.sitebtn.comp_prfl {
  background: #30A9E2;
  font-weight: 500 !important;
  font-size: 15px;
  height: 40px;
  line-height: 25px;
  border: 2px solid transparent;
}

button.sitebtn.resumebtn {
  background: transparent;
  color: #155674;
  border: 2px solid var(--Brand-Blue-Dark, #155674);
  height: 40px;
  font-weight: 500 !important;
}

.w100 { width: 100%;}

.profileform {
  padding: 10px 20px;
}

.profl-block {
  border-radius: 11px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  background: var(--White, #FFF);
  padding: 20px;
  margin-bottom: 20px;
}

.profl-block h5 {
  color: var(--Grey-Dark, #585858);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 0;
}


.customup_btn button {
    border-radius: 11px;
    border: 1px solid var(--Purple-Main, #7054FF);
    background: var(--White, #FFF);
    padding: 20px 30px;
}

.customup_btn button span {
    display: block;
    color: var(--Grey-Dark, #585858);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.customup_btn button .icon {
  border-radius: 20px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  width: 42px;
  height: 42px;
  margin: 7px auto;
  line-height: 42px;
  display: inline-block;
}

.customup_btn button .icon svg {
  font-size: 26px;
  color: #34209B;
}

.fileup_btnhide input {
  height: 120px;
  position: absolute;
  left: 10px;
  opacity: 0;
  cursor: pointer;
  width: 280px;
  top: 0px;
}

.profl-block.formwrap {
  padding-left: 50px;
  padding-right: 50px;
}

.resumwrap {
  padding: 20px 30px 20px 50px;
}

.form-control, .form-select {
  border-radius: 6px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  font-size: 14px;
  height: 44px;
}

.form-control::placeholder {
  color: #afafaf;
  font-weight: 400;
}
.formwrap label.form-label {
  font-size: 14px;
  margin-bottom: 8px;
}

.selectflex {
    display: flex;
    gap: 15px;
}

.selectflex .input-group {
    max-width: 150px;
}

.formwrap textarea {    
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  background: var(--White, #FFF);
  font-size: 14px;
  height: 120px;
}
.appendmore {
  text-align: right;
}

.appendmore button {
  border: 0;
  color: var(--Brand-Blue-Main, #30A9E2);
  font-size: 15px;
  font-weight: 500;
  background: transparent;
}

.finalsubmit {
  text-align: center;
}

button.sitebtn.profilesub {
  border-radius: 6px;
  background: var(--Brand-Blue-Main, #30A9E2);
  height: 50px;
  min-width: 300px;
}

button.sitebtn.profilesub:hover {
  background: #29166f !important;
}
.bgblue {
    background: #30A9E2 !important;
}

.bgblue:hover {
    background: #00B957 !important;
}
.btnblue {
  background: var(--Brand-Blue-Main, #30A9E2);
}

.btnblue:hover {
  background: #29166f !important;
}
.form-select:focus {
  box-shadow: none;
}

aside.sitesidebar {
  width: 280px;
}

.sidemenus  li a { color: #585858;}

ul.steps {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 50px;
  text-align: center;
}

.doc_steps {
  padding: 25px 0;
}

ul.steps li span {
  background: #EBEBEB;
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  border-radius: 50%;
}
ul.steps li.ongoing span {
  background: #BFE7FA;
}
ul.steps li {position: relative;min-width: 138px;}

ul.steps li p {
  text-align: center;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-top: 10px;
}

ul.steps li:before {
  position: absolute;
  content: '';
  left: -80px;
  width: 80%;
  height: 2px;
  background: #EBEBEB;
  top: 22px;
}
ul.steps li:first-child::before,ul.steps li:first-child::after {
  display: none;
}
ul.steps li:after{
  background: #30A9E2;
  position: absolute;
  content: '';
  left: -80px;
  width: 0%;
  transition: all 1s;
  height: 2px;
  top: 22px;
}
ul.steps li.ongoing:after {
  width: 80%;
}

.uplaodrow {
  margin: 0 auto 15px;
}

.uplaodrow label {
  color: var(--Grey-Dark, #585858);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 5px;
}

.docuplods {
  width: 80%;
  margin: 0 auto;
  display: none;
}
.btndocuplods button {
  min-width: 180px;
}
.btndocuplods {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.docuplods.active{ display: block;}
button.docprevbtns {
  background: transparent;
  border: 1px solid var(--Purple-Main, #7054FF);
  color: #585858;
  font-size: 16px;
  font-weight: 600;
   /* 24px */
}

button.docprevbtns:hover {
  border-color: #30a9e2;
}
.customfile_upload button .placeholdertype {
  color: var(--Grey-Main, #AFAFAF);
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
  line-height: 150%; /* 18px */
}

input.cstmfile {
  border-radius: 11px;
  border: 1px solid var(--Purple-Main, #7054FF);
  background: var(--White, #FFF);
  display: block;
  width: 60%;
  height: 44px;
  position: relative;
}

.cstmfile::file-selector-button {
  background: #eee;
  height: 44px;
  color: var(--Grey-Dark, #585858);
  font-size: 12px;
  font-weight: 500;
  width: 135px;
  border: 1px solid #eee;
  border-radius: 10px 0 0 10px;
}



.customfile_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.filenames {
  color: var(--Grey-Main, #AFAFAF);
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; 
}

.contents {
  margin-bottom: 25px;
}

button.skilldoc_btn {
  border-radius: 11px;
  border: 1px solid var(--Purple-Main, #7054FF);
  background: var(--White, #FFF);
  display: block;
  height: 44px;
  position: relative;
  text-align: left;
  width: 80%;
}

.appendbtns_wrap {
  flex: 0 0 70%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.appendbtns {
  display: flex;
  align-items: center;
  gap: 15px;
}

button.addbtn {
  border-radius: 4px;
  background: var(--Green-Light, #C1FFDE);
  border: 0;
  width: 56px;
  height: 36px;
}

button.subtbtn {
  border-radius: 4px;
  background: var(--Green-Light, #FFBBB1);
  border: 0;
  width: 56px;
  height: 36px;
}

.certify_btn input {
  width: 100%;
}

.iconhide { display: none;}

.ps-menu-button:hover {
  background-color: transparent !important;
}

.doctabs .nav-link.active {
  border: 0;
  position: relative;
  padding-left: 35px;
  color: #30A9E2;
  padding-right: 35px;
}

.doctabs.nav-tabs .nav-link:hover {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #30A9E2;
}

.doctabs {
  border-bottom: 2px solid #ebebeb !important;
  gap: 20px;
  padding-left: 20px;
}

.doctabs .nav-link.active:after {
  border-radius: 4px;
  background: var(--Brand-Blue-Main, #30A9E2);
  content: '';
  position: absolute;
  height: 8px;
  width: 100%;
  left: 0;
  bottom: -4px;
}

.doctabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #cfcfcf;
  transition: all 0.5s;
}

.alldoctabs .nav-link {
  color: #29166F !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  border-radius: 6px;
  border: 1px solid var(--Brand-Purple-Light, #D1C5FF);
  background: var(--White, #FFF);
  height: 90px;
  transition:all 0.5s;
  width: 160px;
  text-align: left;
  padding-top: 38px;
  position: relative;
}
.alldoctabs .nav-link:before {
 content: '';
 position: absolute;
 top: 10px;
 background-image: url('images/folders.png');
 height: 24px;
 width: 24px;
 background-repeat: no-repeat;
}
.alldoctabs {
  border: 0;
  justify-content: space-around;
  width: 80%;
  margin: 40px auto;
}

.alldoctabs .nav-link.active,.alldoctabs .nav-link:hover {border-color: #00B957;}

.ur_docwraps td {
  background: rgba(191, 231, 250,0.2) !important;
  border: 0;
  padding: 12px !important;
  vertical-align: middle;
}
.ur_docwraps td span {
  color: #585858;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.ur_docwraps span.error {
  color: #ff2200;
}
.ur_docwraps  th {
  border-color: #D1C5FF;
  border-top: 1px solid #D1C5FF;
  padding: 12px !important;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

span.correct svg {
  color: #00B957;
}

.table_actionbtns {
  gap: 15px;
  align-items: center;
}

button.viewfile {
  border: 0;
  background: transparent;
}

button.viewfile svg {
  color: #161616;
  font-weight: normal;
}

span.reupload {
  cursor: pointer;
}

.modal-title.h4 {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.modal-header button.btn-close {
  font-size: 14px;
}


.modal .uplaodrow {
  width: 90%;
}

.animate {
  stroke-dashoffset: 0 !important; /* Adjust animation effect as needed */
}

.experienceblock-0 .removebtn, .educationblock-0 .removebtn {
  display: none;
}

.experienceblock-1 .currentlywork, .experienceblock-2 .currentlywork,.experienceblock-3 .currentlywork, .experienceblock-4 .currentlywork,
.experienceblock-5 .currentlywork, .experienceblock-6 .currentlywork,.experienceblock-7 .currentlywork, .experienceblock-8 .currentlywork,.experienceblock-9 .currentlywork, .experienceblock-10 .currentlywork{
  display: none;
}
.formwrap .col {
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator { background: transparent; bottom: 0; color: transparent; cursor: pointer; height: auto; left: 0; position: absolute; right: 0; top: 0; width: auto; }

input[type="date"] {
  padding-left: 32px;
}

input[type="date"] + svg {
  position: absolute;
  top: 44px;
  left: 20px;
  color: #7054FF;
  font-size: 18px;
}
.hdrprof_menu .nav-item.dropdown {
  position: absolute;
  width: 50px;
}

.hdrprof_menu .nav-item.dropdown a#basic-nav-dropdown:after {
  opacity: 0;
}

.hdrprof_menu {
  position: relative;
}
textarea:focus-visible {
  outline: none;
}


@media(max-width:980px){

  h3 {
     font-size: 18px;
  }
 .sitecard {
     padding: 10px !important;
     border-radius: 15px !important;
  }
  .searchwrap {
     display: none;
  }
  .emptable_wrap {
     overflow-x: auto;
  }
  .dflexbtwn, .d-flex {
     flex-wrap: wrap;
     gap: 10px;
  }
  aside.ps-collapsed + .wd80.tophdr + .maincontent {
     width: calc(100% - 85px);
     margin-left: 85px;
  }
  .cd_prfright {
   margin: 0!important;
   border: 0!important;
   justify-content: flex-start!important;
 }
 
 .widthcomp .nav-item {
   width: 100% !important;
 }
 
 .infos {
   flex: 0 0 100% !important;
 }
  
 .infobox {
   margin: 0;
 }
 
 .job_summry {
   flex-wrap: wrap;
   gap: 10px !important;
 }
 
 .dtlheadr {
   flex-wrap: wrap;
 }
 
 .jbsum {
   display: flex;
   width: 220px;
   gap: 10px;
   align-items: center;
 }
 .react-calendar {
   margin-bottom: 20px;
 }
 .logincard {
   flex-wrap: wrap;
   width: auto;
   height: auto;
 }
 .rightlogin {
   flex: 0 0 100%;
   text-align: center;
 }

 .logincard .btnright {
   float: none;
 }
 .cd_prfleft .name {
   font-size: 16px;
 }
 .cd_prfright {
   padding-left: 0px !important;
 }
 
 span.star svg {
   color: #d2d2d2;
   font-size: 26px;
 }
 .dtlheadr {
   padding: 5px;
   gap: 10px;
 }
 
 .welcomebox {
  width: 100%;
}

.welcometext h2 {
  font-size: 20px;
}

.welcometext p {
  font-size: 14px;
  margin: 0;
}


aside span.ps-menu-icon {
  height: 35px;
  width: 35px;
}

aside span.ps-menu-icon svg {
  font-size: 15px;
}

.sidemenus li.ps-menuitem-root {
  padding: 10px 0;
}

.sidemenus span.ps-menu-icon:after { bottom: -35px;}
.hdrprof_menu img {
  width: 35px;
}

.contentbox {
  width: 100%;
  margin: 20px 0;
}
.alertbox p {
  font-size: 11px;
}
.alertbox {
  top: -10px;
  width: 100%;
}
.answers label {
  font-size: 14px;
}

.answers {
  gap: 10px;
  flex-direction: column;
}
.answers label {
  font-size: 14px;
}

.answers {
  gap: 10px;
  flex-direction: column;
}

.scorewrap {
  width: 100%;
}
.scorebox {
  padding: 10px;
  flex-direction: column;
}

.scoretext {
  padding: 10px 0px;
  width: 100%;
  text-align: center;
}

.scoretext h5 {
  font-size: 20px;
}

.scoretext p {
  font-size: 14px;
}

.scoretext .dflexbtwn {
  justify-content: center;
}

.scoretext .prflbtns {
  justify-content: center;   
  flex-wrap: wrap;
}
.resumwrap {
  padding: 15px;
}
.profileform {
  padding: 10px;
}

.profl-block.formwrap {
  padding-left: 15px;
  padding-right: 15px;
}

.profl-block.formwrap .col {
  flex: 0 0 100%;
  margin-bottom: 5px;
}
.profl-block.formwrap .mb-3{margin-bottom: 5px !important;}
.selectflex {
  flex-wrap: wrap;
}

.selectflex .input-group {
  max-width: 100%;
}
ul.steps {
  gap: 10px;
}

ul.steps li {
  min-width: 75px;
}

ul.steps li span {
  width: 30px;
  height: 30px;
  font-size: 12px;
}

ul.steps li p {
  font-size: 11px;
}

ul.steps li:before {
  width: 50%;
  top: 15px;
  left: -25px;
}
.docuplods {
  width: 100%;
}

.customfile_upload {
  flex-wrap: wrap;
}

input.cstmfile {
  width: 100%;
  font-size: 12px;
}
ul.steps li.ongoing:after {
  width: 50%;
}
ul.steps li:after {
  top: 15px;
  left: -25px;
}

.doctabs .nav-link.active:after { height: 4px;}

}

ul.benefits {
  flex-wrap:wrap;
  gap:6px;
}

ul.benefits li {
  padding:5px;
  background:#fff;
}

.applyhdr .jbsum p { padding:5px; }




:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  background-color: #EBEBEB;
  padding: 0;
  font-family: "Poppins", sans-serif;
  height: 100%;
  min-height: 100vh;
}


@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.loginwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.logincard {
  border-radius: 17px;
  background: var(--White, #FFF);
  box-shadow: 0px 0px 11.7px 1px rgba(48, 169, 226, 0.23);
  padding: 20px;
  display: flex;
  max-width: 700px;
  width: 700px;
  justify-content: space-around;
  gap: 26px;
  height: 310px;
  align-items: center;
  position: relative;
  z-index: 9999;
}

.leftlogin h5 {
  color: var(--Brand-Purple-Main, #29166F);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}
.logincard:after {
  content: '';
  position: absolute;
  background: #30A9E2;
  width: 0%;
  left: 18px;
  height: 7px;
  transition: all 0.5s;
  bottom: -8px;
  z-index: 1;
  border-radius: 0px 0 15px 15px;
}
.logincard.highlight:after {
  width: 95%;

}
.rightlogin h3 {
  color: var(--Grey-Dark, #585858);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

label.form-label {
  color: var(--Grey-Dark, #585858);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.leftlogin {
  flex: 0 0 40%;
  text-align: center;
}

.rightlogin {
  flex: 0 0 50%;
}
.logform { overflow: auto;}
.btnright {
  float: right;
}

.formbtn {
  border-radius: 9px;
  background: var(--Brand-Blue-Main, #30A9E2);
  border: 0;
  min-width: 100px;
  height: 41px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.formbtn:hover{ background: var(--Brand-Blue-Dark, #155674);}
.sitelinks ul {
  display: flex;
  list-style: none;
  gap: 30px;
  margin-top: 18px;
  padding: 0;
}

.sitelinks { max-width:700px; width:700px;display: flex;justify-content: flex-end;padding-right: 50px; }

.sitelinks ul a {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control {
  border-radius: 4px;
  border: 1px solid var(--Grey-Main, #AFAFAF);
  height: 41px;
}

.form-control:focus { box-shadow: none; }

.inputwrp {
  position: relative;
}

.inputwrp svg {
  position: absolute;
  top: 14px;
  font-size: 16px;
  left: 10px;
  color: #585858;
}

.inputwrp input {
  padding-left: 32px;
}
.otp-field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--Grey-Main, #AFAFAF);
}
p.resend.mt-3 {
  color: var(--Grey-Dark, #585858);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

span.timer {
  color: #00B957;
}

span.timer svg {
  color: #585858;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.btnicon svg {
  position: relative;
  right: 8px;
  color: #187142;
}

.dashlogo img {
  max-width: 90px;
}

.sidelogobox {
  padding: 9px 0;
  border-bottom: 1px solid var(--Brand-Purple-Light, #D1C5FF);
}
aside .ps-sidebar-container {
  border-right: 1px solid var(--Brand-Purple-Light, #D1C5FF);
  background-color: #fff;
}

aside .dashlogo {
  margin-left: 15px;
}

aside span.ps-menu-icon {
  border-radius: 24.038px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  color: #585858;
  height: 47px;
  position: relative;
  transition: all 0.5s;
  width: 47px;
}

aside span.ps-menu-icon svg {
  font-size: 20px;
}

.sidemenus li.ps-menuitem-root {
  padding: 22px 0;
}

.sidemenus span.ps-menu-icon:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 3px;
  background: #bfe7fa;
  bottom: -47px;
}

.sidemenus .ps-menuitem-root:last-child span.ps-menu-icon:after{
 display: none;
}


.tophdr {
  width: calc(100% - 280px);
  margin-left: 280px;
  background: #fff;
  height:69px;
  border-bottom: 1px solid var(--Brand-Purple-Light, #D1C5FF);

}

.maincontent{
  width: calc(100% - 280px);
  margin-left: 280px;
}

.ps-collapsed + .tophdr {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.ps-collapsed + .tophdr + .maincontent {
  margin-left: 80px;
  width: calc(100% - 80px );
}
.welcomebox {
  border-radius: 10px;
  background: var(--White, #FFF);
  width:80%;
  padding:60px 0;
  margin: 8% auto;
  text-align: center;
}

.welcometext h2 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 30px;
  font-style: normal;
  line-height: 150%; /* 45px */
}

.welcometext p {
  color: var(--Grey-Dark, #160E31);
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
}

.sitebtn {
  border-radius: 6px;
  background: var(--Green-Main, #00B957);
  border: 0;
  padding: 6px 15px;
  color: var(--White, #FFF);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: all 0.5s;
  display: inline-block;
  min-width: 210px;
  height: 44px;
}

.sitebtn:hover {
  background: #30a9e2;
  color:#fff;
}

.contentbox {
  border-radius: 10px;
  background: var(--White, #FFF);
  width: 80%;
  padding: 0 0 12px 0px;
  margin: 55px auto 0;
}

.contentbody {
  padding: 0 20px;
  height: 400px;
  overflow-y: auto;
}

.contenthdr {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #AFAFAF;
  margin-bottom: 12px;
  padding-bottom: 10px;
}
.sidemenus li.active .ps-menu-icon,.sidemenus li a:hover .ps-menu-icon {
  background: #29166F;
  color: #fff;
}

.sidemenus li.active .ps-menu-icon::after {
  background: #29166f;
}
.contentbody p {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; 
}

.contentbody h6 {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.contentbody h5 {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.contenthdr h4 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 150%; /* 33px */
}

.contenthdr .statictime {
  margin: 0;
  border-radius: 5px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  padding: 4px 10px;
  color: var(--Brand-Blue-Dark, #155674);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%; /* 21px */
}

.contentbtnwrap {
  text-align: center;
  margin-top: 22px;
}

.contentbtnwrap .sitebtn {
  min-width: 280px;
  padding: 10px 15px;
}

.topdashhdr {
  padding-right: 25px;
  gap: 15px;
}

.notf_icn {
  border-radius: 19px;
  background: var(--Grey-Light, #EBEBEB);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notf_icn svg {
  font-size: 16px;
}

.hdrprof_menu img {
  width: 42px;
}

.answers {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.answers label {
  color: var(--Grey-Dark, #585858);
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  /* 22.5px */
}

.questionwrap h6 {
  font-size: 14px;
}

.questionwrap {
  margin-bottom: 10px;
}

.qstnbtn {
  min-width: 180px !important;
}

.sitebtn.disabled {
  background: #AFAFAF;
  cursor: not-allowed;
}



.contentbody::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #BFE7FA;
}

.contentbody::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.contentbody::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #30A9E2;
}
.alertbox {
  border-radius: 9px;
  background: var(--Brand-Purple-Light, #D1C5FF);
  padding: 7px;
  width: 82%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -45px;
}

.alertbox p {
  margin:0;
  text-align:center;
  font-size:14px;
  font-weight:600;
}

.contentwrap {
  padding-bottom: 50px;
  position: relative;
}

.dflexbtwn { display:flex; justify-content:space-between; align-items:center; }
.scorebox {
  border-radius: 10px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding:20px 25px;
}
.scorenumb {
  width: 130px;
  height: 130px;
}

.scoretext {
  padding-left: 30px;
  width: 70%;
}

.scorewrap {
  margin: 55px auto 0;
  width: 85%;
}
.resumwrap svg {
  color: #00B957;
}
.assementscore .CircularProgressbar .CircularProgressbar-path {
  stroke: #00B957;
}
.assementscore .CircularProgressbar .CircularProgressbar-text {
  fill: #00B957;
  font-weight: 500;
}
.assementscore.failed .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff2200;
}

.assementscore.failed .CircularProgressbar .CircularProgressbar-text {
  fill: #ff2200;
  font-weight: 500;
}

.failed .scoretext span {
  color: #ff2200;
}
.scoretext h5 {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 26px;
  font-style: normal;
  line-height: 150%; /* 45px */
}

.scoretext p {
  color: var(--Brand-Purple-Dark, #160E31);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
}

.scoretext span {
  color: var(--Green-Dark, #187142);
  font-size: 25.2px;
  font-style: normal;
  line-height: 150%; /* 40.8px */
}

button.retakebtn {
  border-radius: 6px;
  background: var(--Green-Light, #C1FFDE) !important;
  color: var(--Green-Dark, #187142) !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

p.scores {
  margin: 0;
}

.prflbtns{
  display: flex;
  align-items: center;
  gap: 25px;
}

.sitebtn.comp_prfl {
  background: #30A9E2;
  font-weight: 500 !important;
  font-size: 15px;
  height: 40px;
  line-height: 25px;
  border: 2px solid transparent;
}

button.sitebtn.resumebtn {
  background: transparent;
  color: #155674;
  border: 2px solid var(--Brand-Blue-Dark, #155674);
  height: 40px;
  font-weight: 500 !important;
}

.w100 { width: 100%;}

.profileform {
  padding: 10px 20px;
}

.profl-block {
  border-radius: 11px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  background: var(--White, #FFF);
  padding: 20px;
  margin-bottom: 20px;
}

.profl-block h5 {
  color: var(--Grey-Dark, #585858);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 0;
}


.customup_btn button {
    border-radius: 11px;
    border: 1px solid var(--Purple-Main, #7054FF);
    background: var(--White, #FFF);
    padding: 20px 30px;
}

.customup_btn button span {
    display: block;
    color: var(--Grey-Dark, #585858);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.customup_btn button .icon {
  border-radius: 20px;
  background: var(--Brand-Blue-Light, #BFE7FA);
  width: 42px;
  height: 42px;
  margin: 7px auto;
  line-height: 42px;
  display: inline-block;
}

.customup_btn button .icon svg {
  font-size: 26px;
  color: #34209B;
}

.fileup_btnhide input {
  height: 120px;
  position: absolute;
  left: 10px;
  opacity: 0;
  cursor: pointer;
  width: 280px;
  top: 0px;
}

.profl-block.formwrap {
  padding-left: 50px;
  padding-right: 50px;
}

.resumwrap {
  padding: 20px 30px 20px 50px;
}

.form-control, .form-select {
  border-radius: 6px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  font-size: 14px;
  height: 44px;
}

.form-control::placeholder {
  color: #afafaf;
  font-weight: 400;
}
.formwrap label.form-label {
  font-size: 14px;
  margin-bottom: 8px;
}

.selectflex {
    display: flex;
    gap: 15px;
}

.selectflex .input-group {
    max-width: 150px;
}

.formwrap textarea {    
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  background: var(--White, #FFF);
  font-size: 14px;
  height: 120px;
}
.appendmore {
  text-align: right;
}

.appendmore button {
  border: 0;
  color: var(--Brand-Blue-Main, #30A9E2);
  font-size: 15px;
  font-weight: 500;
  background: transparent;
}

.finalsubmit {
  text-align: center;
}

button.sitebtn.profilesub {
  border-radius: 6px;
  background: var(--Brand-Blue-Main, #30A9E2);
  height: 50px;
  min-width: 300px;
}

button.sitebtn.profilesub:hover {
  background: #29166f !important;
}
.bgblue {
    background: #30A9E2 !important;
}

.bgblue:hover {
    background: #00B957 !important;
}
.btnblue {
  background: var(--Brand-Blue-Main, #30A9E2);
}

.btnblue:hover {
  background: #29166f !important;
}
.form-select:focus {
  box-shadow: none;
}

aside.sitesidebar {
  width: 280px;
}

.sidemenus  li a { color: #585858;}

ul.steps {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 50px;
  text-align: center;
}

.doc_steps {
  padding: 25px 0;
}

ul.steps li span {
  background: #EBEBEB;
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  border-radius: 50%;
}
ul.steps li.ongoing span {
  background: #BFE7FA;
}
ul.steps li {position: relative;min-width: 138px;}

ul.steps li p {
  text-align: center;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-top: 10px;
}

ul.steps li:before {
  position: absolute;
  content: '';
  left: -80px;
  width: 80%;
  height: 2px;
  background: #EBEBEB;
  top: 22px;
}
ul.steps li:first-child::before,ul.steps li:first-child::after {
  display: none;
}
ul.steps li:after{
  background: #30A9E2;
  position: absolute;
  content: '';
  left: -80px;
  width: 0%;
  transition: all 1s;
  height: 2px;
  top: 22px;
}
ul.steps li.ongoing:after {
  width: 80%;
}

.uplaodrow {
  margin: 0 auto 15px;
}

.uplaodrow label {
  color: var(--Grey-Dark, #585858);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 5px;
}

.docuplods {
  width: 80%;
  margin: 0 auto;
  display: none;
}
.btndocuplods button {
  min-width: 180px;
}
.btndocuplods {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.docuplods.active{ display: block;}
button.docprevbtns {
  background: transparent;
  border: 1px solid var(--Purple-Main, #7054FF);
  color: #585858;
  font-size: 16px;
  font-weight: 600;
   /* 24px */
}

button.docprevbtns:hover {
  border-color: #30a9e2;
}
.customfile_upload button .placeholdertype {
  color: var(--Grey-Main, #AFAFAF);
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
  line-height: 150%; /* 18px */
}

input.cstmfile {
  border-radius: 11px;
  border: 1px solid var(--Purple-Main, #7054FF);
  background: var(--White, #FFF);
  display: block;
  width: 60%;
  height: 44px;
  position: relative;
}

.cstmfile::file-selector-button {
  background: #eee;
  height: 44px;
  color: var(--Grey-Dark, #585858);
  font-size: 12px;
  font-weight: 500;
  width: 135px;
  border: 1px solid #eee;
  border-radius: 10px 0 0 10px;
}



.customfile_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.filenames {
  color: var(--Grey-Main, #AFAFAF);
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; 
}

.contents {
  margin-bottom: 25px;
}

button.skilldoc_btn {
  border-radius: 11px;
  border: 1px solid var(--Purple-Main, #7054FF);
  background: var(--White, #FFF);
  display: block;
  height: 44px;
  position: relative;
  text-align: left;
  width: 80%;
}

.appendbtns_wrap {
  flex: 0 0 70%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.appendbtns {
  display: flex;
  align-items: center;
  gap: 15px;
}

button.addbtn {
  border-radius: 4px;
  background: var(--Green-Light, #C1FFDE);
  border: 0;
  width: 56px;
  height: 36px;
}

button.subtbtn {
  border-radius: 4px;
  background: var(--Green-Light, #FFBBB1);
  border: 0;
  width: 56px;
  height: 36px;
}

.certify_btn input {
  width: 100%;
}

.iconhide { display: none;}

.ps-menu-button:hover {
  background-color: transparent !important;
}

.doctabs .nav-link.active {
  border: 0;
  position: relative;
  padding-left: 35px;
  color: #30A9E2;
  padding-right: 35px;
}

.doctabs.nav-tabs .nav-link:hover {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #30A9E2;
}

.doctabs {
  border-bottom: 2px solid #ebebeb !important;
  gap: 20px;
  padding-left: 20px;
}

.doctabs .nav-link.active:after {
  border-radius: 4px;
  background: var(--Brand-Blue-Main, #30A9E2);
  content: '';
  position: absolute;
  height: 8px;
  width: 100%;
  left: 0;
  bottom: -4px;
}

.doctabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #cfcfcf;
  transition: all 0.5s;
}

.alldoctabs .nav-link {
  color: #29166F !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  border-radius: 6px;
  border: 1px solid var(--Brand-Purple-Light, #D1C5FF);
  background: var(--White, #FFF);
  height: 90px;
  transition:all 0.5s;
  width: 160px;
  text-align: left;
  padding-top: 38px;
  position: relative;
}
.alldoctabs .nav-link:before {
 content: '';
 position: absolute;
 top: 10px;
 background-image: url('images/folders.png');
 height: 24px;
 width: 24px;
 background-repeat: no-repeat;
}
.alldoctabs {
  border: 0;
  justify-content: space-around;
  width: 80%;
  margin: 40px auto;
}

.alldoctabs .nav-link.active,.alldoctabs .nav-link:hover {border-color: #00B957;}

.ur_docwraps td {
  background: rgba(191, 231, 250,0.2) !important;
  border: 0;
  padding: 12px !important;
  vertical-align: middle;
}
.ur_docwraps td span {
  color: #585858;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.ur_docwraps span.error {
  color: #ff2200;
}
.ur_docwraps  th {
  border-color: #D1C5FF;
  border-top: 1px solid #D1C5FF;
  padding: 12px !important;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

span.correct svg {
  color: #00B957;
}

.table_actionbtns {
  gap: 15px;
  align-items: center;
}

button.viewfile {
  border: 0;
  background: transparent;
}

button.viewfile svg {
  color: #161616;
  font-weight: normal;
}

span.reupload {
  cursor: pointer;
}

.modal-title.h4 {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.modal-header button.btn-close {
  font-size: 14px;
}


.modal .uplaodrow {
  width: 90%;
}

.animate {
  stroke-dashoffset: 0 !important; /* Adjust animation effect as needed */
}

.experienceblock-0 .removebtn, .educationblock-0 .removebtn {
  display: none;
}

.experienceblock-1 .currentlywork, .experienceblock-2 .currentlywork,.experienceblock-3 .currentlywork, .experienceblock-4 .currentlywork,
.experienceblock-5 .currentlywork, .experienceblock-6 .currentlywork,.experienceblock-7 .currentlywork, .experienceblock-8 .currentlywork,.experienceblock-9 .currentlywork, .experienceblock-10 .currentlywork{
  display: none;
}
.formwrap .col {
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator { background: transparent; bottom: 0; color: transparent; cursor: pointer; height: auto; left: 0; position: absolute; right: 0; top: 0; width: auto; }

input[type="date"] {
  padding-left: 32px;
}

input[type="date"] + svg {
  position: absolute;
  top: 44px;
  left: 20px;
  color: #7054FF;
  font-size: 18px;
}
.hdrprof_menu .nav-item.dropdown {
  position: absolute;
  width: 50px;
}

.hdrprof_menu .nav-item.dropdown a#basic-nav-dropdown:after {
  opacity: 0;
}

.hdrprof_menu {
  position: relative;
}
textarea:focus-visible {
  outline: none;
}


@media(max-width:980px){

  h3 {
     font-size: 18px;
  }
 .sitecard {
     padding: 10px !important;
     border-radius: 15px !important;
  }
  .searchwrap {
     display: none;
  }
  .emptable_wrap {
     overflow-x: auto;
  }
  .dflexbtwn, .d-flex {
     flex-wrap: wrap;
     gap: 10px;
  }
  aside.ps-collapsed + .wd80.tophdr + .maincontent {
     width: calc(100% - 85px);
     margin-left: 85px;
  }
  .cd_prfright {
   margin: 0!important;
   border: 0!important;
   justify-content: flex-start!important;
 }
 
 .widthcomp .nav-item {
   width: 100% !important;
 }
 
 .infos {
   flex: 0 0 100% !important;
 }
  
 .infobox {
   margin: 0;
 }
 
 .job_summry {
   flex-wrap: wrap;
   gap: 10px !important;
 }
 
 .dtlheadr {
   flex-wrap: wrap;
 }
 
 .jbsum {
   display: flex;
   width: 220px;
   gap: 10px;
   align-items: center;
 }
 .react-calendar {
   margin-bottom: 20px;
 }
 .logincard {
   flex-wrap: wrap;
   width: auto;
   height: auto;
 }
 .rightlogin {
   flex: 0 0 100%;
   text-align: center;
 }

 .logincard .btnright {
   float: none;
 }
 .cd_prfleft .name {
   font-size: 16px;
 }
 .cd_prfright {
   padding-left: 0px !important;
 }
 
 span.star svg {
   color: #d2d2d2;
   font-size: 26px;
 }
 .dtlheadr {
   padding: 5px;
   gap: 10px;
 }
 
 .welcomebox {
  width: 100%;
}

.welcometext h2 {
  font-size: 20px;
}

.welcometext p {
  font-size: 14px;
  margin: 0;
}


aside span.ps-menu-icon {
  height: 35px;
  width: 35px;
}

aside span.ps-menu-icon svg {
  font-size: 15px;
}

.sidemenus li.ps-menuitem-root {
  padding: 10px 0;
}

.sidemenus span.ps-menu-icon:after { bottom: -35px;}
.hdrprof_menu img {
  width: 35px;
}

.contentbox {
  width: 100%;
  margin: 20px 0;
}
.alertbox p {
  font-size: 11px;
}
.alertbox {
  top: -10px;
  width: 100%;
}
.answers label {
  font-size: 14px;
}

.answers {
  gap: 10px;
  flex-direction: column;
}
.answers label {
  font-size: 14px;
}

.answers {
  gap: 10px;
  flex-direction: column;
}

.scorewrap {
  width: 100%;
}
.scorebox {
  padding: 10px;
  flex-direction: column;
}

.scoretext {
  padding: 10px 0px;
  width: 100%;
  text-align: center;
}

.scoretext h5 {
  font-size: 20px;
}

.scoretext p {
  font-size: 14px;
}

.scoretext .dflexbtwn {
  justify-content: center;
}

.scoretext .prflbtns {
  justify-content: center;   
  flex-wrap: wrap;
}
.resumwrap {
  padding: 15px;
}
.profileform {
  padding: 10px;
}

.profl-block.formwrap {
  padding-left: 15px;
  padding-right: 15px;
}

.profl-block.formwrap .col {
  flex: 0 0 100%;
  margin-bottom: 5px;
}
.profl-block.formwrap .mb-3{margin-bottom: 5px !important;}
.selectflex {
  flex-wrap: wrap;
}

.selectflex .input-group {
  max-width: 100%;
}
ul.steps {
  gap: 10px;
}

ul.steps li {
  min-width: 75px;
}

ul.steps li span {
  width: 30px;
  height: 30px;
  font-size: 12px;
}

ul.steps li p {
  font-size: 11px;
}

ul.steps li:before {
  width: 50%;
  top: 15px;
  left: -25px;
}
.docuplods {
  width: 100%;
}

.customfile_upload {
  flex-wrap: wrap;
}

input.cstmfile {
  width: 100%;
  font-size: 12px;
}
ul.steps li.ongoing:after {
  width: 50%;
}
ul.steps li:after {
  top: 15px;
  left: -25px;
}

.doctabs .nav-link.active:after { height: 4px;}



}


/* .flash-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #32a9df; 
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
}
.flash-message.hidden {
  opacity: 0;
} */

.flash-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745; /* Green background for success */
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(0);
  display: flex;
  align-items: center;
  gap: 10px;
}

.flash-message.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.flash-message.error {
  background-color: #dc3545; /* Red background for error */
}

.flash-message .icon {
  font-size: 20px;
}

.flash-message .close {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.autocomplete-container {
  position: relative;
}

.location-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000; /* Ensure dropdown is on top of other content */
}

.location-options li {
  padding: 8px;
  cursor: pointer;
}

.location-options li:hover {
  background-color: #f0f0f0;
}



.designation-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000; /* Ensure dropdown is on top of other content */
}

.designation-options li {
  padding: 8px;
  cursor: pointer;
}

.designation-options li:hover {
  background-color: #f0f0f0;
}



.applied-from-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000; /* Ensure dropdown is on top of other content */
}

.applied-from-options li {
  padding: 8px;
  cursor: pointer;
}

.applied-from-options li:hover {
  background-color: #f0f0f0;
}


.degree-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000; /* Ensure dropdown is on top of other content */
}

.degree-options li {
  padding: 8px;
  cursor: pointer;
}

.degree-options li:hover {
  background-color: #f0f0f0;
}


/* Modal custom styling */
.attractive-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fefefe;
  color: #333;
}

.attractive-modal .modal-header {
  border-bottom: none;
  background: #007bff;
  color: white;
  border-radius: 10px 10px 0 0;
  padding: 20px;
}

.attractive-modal .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.attractive-modal .modal-body {
  padding: 30px;
  text-align: center;
}

.attractive-modal .modal-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 20px;
}

.attractive-modal .modal-message {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555;
}

.attractive-modal .sitebtn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.attractive-modal .sitebtn:hover {
  background-color: #0056b3;
}

/* Optional: Add a backdrop style if needed */
.modal-backdrop.show {
  opacity: 0.5; /* Adjust backdrop opacity if needed */
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

span.rejected svg {
  color: red; /* Red color for rejected status */
}

.verifiedPg{ padding: 20px;}

img { max-width: 100%;}


.dflexbtwn { display:flex; justify-content:space-between; align-items:center; }
.dflexbtwn, .d-flex {
  flex-wrap: wrap;
  gap: 10px;
}
.jbsum_dtls.dflexbtwn {
  width: 100% !important;
}

.sitecard {
  border-radius: 26px;
  border: 1px solid var(--Purple-Light, #D2C9FF);
  background: var(--White, #FFF);
  padding: 20px;
  margin-bottom: 20px;
}

.requistn_form .form-label, .approvalmodal .form-label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; 
}

.requistn_form .form-check-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}


.requistn_form  textarea , .approvalmodal textarea {
  min-height: 110px !important;
}

.requistn_form .cstmfile::file-selector-button {
  background: #29166F;
  color: #fff;
  border-color: #29166F;
}






